import React from 'react'
import styled from '@emotion/styled'
// import { navigate } from 'gatsby'

import { media } from '../../styles/utils'
import { config } from 'styles/config'

import { Headline } from '../../styles/typography'
import { SectionLayout } from 'layouts/SectionLayout'

import brand from '../../assets/images/fps-brand.png'

const MAILING_LINK = process.env.GATSBY_MAILING_LINK

const PADDING_NORMAL = '6vw' // screen size < 1440
const PADDING_LARGE = '86.4px' // 6vw at a screen size of 1440px

const Container = styled.nav`
  background: #FFF;
  height: ${p => p.theme.measurements.navigationBarHeight}px;
  z-index: ${p => p.theme.layers.nav};
  box-sizing: content-box;

  display: flex;
  justify-content: space-between;

  padding-top: ${PADDING_LARGE};
  ${media.xlarge`
    padding-top: ${PADDING_NORMAL};
  `}
`

const NavLayout = styled(SectionLayout)`
  padding: 0;
  width: 100%;

  ${media[config.mobileBreakpoint]`
    padding: 0;
  `}
`

// const BrandContainer = styled.a`
const BrandContainer = styled.div`
  height: 100%;
  width: 300px;
  display: flex;
  align-items: center;
  // justify-content: center;

  ${media[config.mobileBreakpoint]`
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  `}
`

const Brand = styled(Headline)`
  font-weight: 300;
`

// const List = styled.ul`
//   display: flex;
//   align-items: center;
// `

// const ListItem = styled.li`
//   margin-left: 2.25em;
// `

export const Nav = () => {

  const handleContact = () => {
    // TODO add analytics
    window.location.href = `mailto:${MAILING_LINK}`
  }

  return (
      <Container>
        <NavLayout>
          <BrandContainer className='c-2-8'>
          {/* <BrandContainer onClick={() => navigate('/')}> */}
            <img src={brand} alt='brand' />
          </BrandContainer>
        </NavLayout>


        {/* <List>
          <ListItem>
            <a onClick={handleContact}>
              <Body className='mb-0'>Contact</Body>
            </a>
          </ListItem>
        </List> */}

      </Container>
  )
}
