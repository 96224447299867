export const copywriting = {
  home: {
    hero: `We are a technical consultancy that partners with founders to develop digital solutions.`,
    caption: `
      Using relevant technologies we architect and deliver
      solutions to help businesses get products to market, fast.
    `,
    statement: `
      Our approach is immersive and pragmatic. We take the technical lead and manage all aspects of product design, development and implementation, whilst informing and shaping business processes.
    `,
    approach: [
      {
        heading: `From ideation to actualisation`,
        subheading: `We develop digital products and experiences. Our initial consultation, we evaluate where you are in the project and deliver a reports that includes:
        - recommendations based on current technology and architecture
        - along with their limitations and pathways
        - Proposed changes and pathways
          - based on budget and resourcing
        `
      },
      {
        heading: `Your trusted technical filter`,
        subheading: `Through direct consultation, our aim is to be ... Using emergent technologies in engineering, computer science and software development we orchestrate multiple project components to deliver solutions that grow with your business.`
      },
      {
        heading: `Proven Process`,
        subheading: `Our process is proven through experience across multiple industries and our work is driven by a pragmatic attitude. Development of critical path for execution and roadmap for future development.
        - Product strategy
        - Development of business and revenues models
        - User based wire-framing and design
        - definition of key stakeholders and user personas.`
      },
      {
        heading: `Products that grow with your business`,
        subheading: `Solutions Architecture that includes:
        - Web Architecture and Development Framework
        - Operations pipeline and CI/CD development
        - Middleware and Integration Software
        - Network design and Information Security
        - Mobile Development
        - Database Engineering
        - User Interface Design and Front-end Development
        - Project management workflow`
      },
      {
        heading: `Something about being quick`,
        subheading: ``
      }
    ],
    services: [
      {
        category: 'Blueprinting',
        items: ['Consulting', 'Technical Auditing', 'Roadmapping']
      },
      {
        category: 'Business Development',
        items: ['Product strategy', 'Business and revenue modelling', 'Wireframing']
      },
      {
        category: 'Product Development',
        items: ['Solutions Architecture', 'Web and mobile development', 'Software delivery management', 'Operations / CI / CD']
      },
    ],
    contact: {
      text: `We're always happy to have a chat.`,
      callToAction: `Get in touch`
    }
  }
}
