import { theme } from './theme'
import styled from '@emotion/styled'

import { config } from './config'
import { media, fluidType, screenSizes } from './utils'

// https://developer.apple.com/ios/human-interface-guidelines/visual-design/typography/
export const human = {
  largeTitle: {
    fontSize: `${theme.scales[`${config.activeScale}`][9]}em`,
    fontWeight: 700,
    lineHeight: 1.4,
    letterSpacing: '-0.015em',
    marginBottom: '0.667em'
  },
  title: {
    fontSize: `${theme.scales[`${config.activeScale}`][8]}em`,
    fontWeight: 700,
    lineHeight: 1.3,
    letterSpacing: '-0.015em',
    marginBottom: '0.8em'
  },
  title2: {
    fontSize: `${theme.scales[`${config.activeScale}`][7]}em`,
    fontWeight: 700,
    lineHeight: 1.3,
    letterSpacing: '-0.015em',
    marginBottom: '0.667em'
  },
  title3: {
    fontSize: `${theme.scales[`${config.activeScale}`][6]}em`,
    fontWeight: 700,
    lineHeight: 1.4,
    letterSpacing: '-0.015em',
    marginBottom: '0.667em'
  },
  headline: {
    fontSize: `${theme.scales[`${config.activeScale}`][4]}em`,
    lineHeight: 1.45,
    letterSpacing: 0,
    marginBottom: '0.8em'
  },
  statement: {
    fontSize: `${theme.scales[`${config.activeScale}`][4]}em`,
    lineHeight: 1.55,
    letterSpacing: '-0.015em',
  },
  callout: {
    fontSize: `${theme.scales[`${config.activeScale}`][3]}em`,
    lineHeight: 1.55,
    letterSpacing: '-0.015em',
    color: theme.colors.body
  },
  subhead: {
    fontSize: `${theme.scales[`${config.activeScale}`][2]}em`,
    lineHeight: 1.55,
    letterSpacing: '-0.015em',
    color: theme.colors.body
  },
  body: {
    fontSize: `${theme.scales[`${config.activeScale}`][1]}em`,
    lineHeight: 1.55,
    letterSpacing: '-0.015em',
    color: theme.colors.body
  },
  caption: {
    fontSize: `${theme.scales[`${config.activeScale}`][0]}em`,
    lineHeight: 1.55,
    letterSpacing: '-0.015em',
    color: theme.colors.body
  }
}

export const LargeTitle = styled.h1(human.largeTitle)
export const BaseTitle = styled.h2(human.title)
export const BaseTitle2 = styled.h3(human.title2)
export const BaseTitle3 = styled.h4(human.title3)
export const BaseHeadline = styled.h5(human.headline)
export const BaseStatement = styled.p(human.statement)
export const BaseCallout = styled.p(human.callout)
export const Subhead = styled.p(human.subhead)
export const Body = styled.p(human.body)
export const Caption = styled.p(human.caption)

/**
|--------------------------------------------------
| Additional
|--------------------------------------------------
*/

export const InputText = styled(Body)`
  margin-left: 0.667em;
  margin-right: 0.667em;
  color: ${p => p.theme.colors.inputPlaceholder};
`

/**
|--------------------------------------------------
| Responsive
|--------------------------------------------------
*/

const lowerRange = screenSizes.small / config.baseFontSize
const upperRange = screenSizes.xlarge / config.baseFontSize

export const Title = styled(BaseTitle)`
  ${media.xlarge`
    ${fluidType(theme.scales[`${config.activeScale}`][5], theme.scales[`${config.activeScale}`][8], lowerRange, upperRange)}
  `}
  ${media.small`
    font-size: ${theme.scales[`${config.activeScale}`][5]}em;
  `}
`

export const Title2 = styled(BaseTitle2)`
  ${media.xlarge`
    ${fluidType(theme.scales[`${config.activeScale}`][4], theme.scales[`${config.activeScale}`][7], lowerRange, upperRange)}
  `}
  ${media.small`
    font-size: ${theme.scales[`${config.activeScale}`][4]}em;
  `}
`

export const Title3 = styled(BaseTitle3)`
  ${media.xlarge`
    ${fluidType(theme.scales[`${config.activeScale}`][4], theme.scales[`${config.activeScale}`][6], lowerRange, upperRange)}
  `}
  ${media.small`
    font-size: ${theme.scales[`${config.activeScale}`][4]}em;
  `}
`

export const Headline = styled(BaseHeadline)`
  ${media.xlarge`
    ${fluidType(theme.scales[`${config.activeScale}`][3], theme.scales[`${config.activeScale}`][4], lowerRange, upperRange)}
  `}
  ${media.small`
    font-size: ${theme.scales[`${config.activeScale}`][3]}em;
  `}
`

export const Statement = styled(BaseStatement)`
  ${media.xlarge`
    ${fluidType(theme.scales[`${config.activeScale}`][2], theme.scales[`${config.activeScale}`][4], lowerRange, upperRange)}
  `}
  ${media.phone`
    font-size: ${theme.scales[`${config.activeScale}`][2]}em;
  `}
`

export const Callout = styled(BaseCallout)`
  ${media.xlarge`
    ${fluidType(theme.scales[`${config.activeScale}`][2], theme.scales[`${config.activeScale}`][3], lowerRange, upperRange)}
  `}
  ${media.phone`
    font-size: ${theme.scales[`${config.activeScale}`][2]}em;
  `}
`



