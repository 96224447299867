import React from 'react'
import styled from '@emotion/styled'
import * as THREE from 'three'
import debounce from 'lodash/debounce'

import baseVertex from './baseVertex.glsl'
import baseFragment from './baseFragment.glsl'

const Container = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};

  filter: grayscale(1);
  opacity: 0.1;

  ${props => props.responsive
    ? `width: 100%;
     height: 100%;`
    : ``}

  ${props => props.background
    ? `position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;`
    : ``}
`

interface ShaderScreenProps {
  shader: string
  width?: number
  height?: number
  responsive?: boolean
  background?: boolean
}

export class ShaderScreen extends React.Component<ShaderScreenProps> {
  constructor (props) {
    super(props)
    this.debouncedOnResize = debounce(this.onResize, 200)
  }

  componentDidMount () {
    const width = this.mount.clientWidth
    const height = this.mount.clientHeight

    // Scene
    this.scene = new THREE.Scene()

    // Renderer
    const renderer = new THREE.WebGLRenderer()
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setClearColor(0xF0F8FF)
    renderer.setSize(width, height)
    this.renderer = renderer

    // Camera
    this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1)

    // Shader
    const resolution = new THREE.Vector2(width, height)

    let uniforms = {
      iGlobalTime: {
        type: 'f',
        value: 1
      },
      iResolution: {
        type: 'v2',
        value: resolution
      }
    }

    const { shader } = this.props

    const shaderMaterial = new THREE.ShaderMaterial({
      uniforms,
      vertexShader: baseVertex,
      fragmentShader: shader || baseFragment
    })

    // Geometries
    this.geometry = new THREE.PlaneBufferGeometry(2, 2)

    this.mesh = new THREE.Mesh(this.geometry, shaderMaterial)
    this.scene.add(this.mesh)

    // Initialise Scene
    this.mount.appendChild(this.renderer.domElement)
    this.start()

    window.addEventListener('resize', this.debouncedOnResize)
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = window.requestAnimationFrame(this.animate)
    }
  }

  animate = (timestamp) => {
    this.mesh.material.uniforms.iGlobalTime.value = timestamp / 1000

    this.renderScene()
    this.frameId = window.requestAnimationFrame(this.animate)
  }

  renderScene = () => {
    this.renderer.render(this.scene, this.camera)
  }

  componentWillUnmount() {
    this.stop()
    this.mount.removeChild(this.renderer.domElement)
    window.removeEventListener('resize', this.debouncedOnResize)
  }

  stop = () => {
    cancelAnimationFrame(this.frameId)
  }

  onResize = event => {
    const width = this.mount.clientWidth
    const height = this.mount.clientHeight
    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(width, height)
  }

  render() {
    const { width, height, responsive, background } = this.props
    return (
      <Container
        className='shader-screen'
        innerRef={mount => this.mount = mount}
        width={width}
        height={height}
        responsive={responsive}
        background={background}
      />
    )
  }
}

