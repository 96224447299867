import React from 'react'
import styled from '@emotion/styled'

import { Statement } from 'styles/typography'
// import { PrimaryButton } from 'components/buttons/PrimaryButton'
import { SectionLayout } from 'layouts/SectionLayout'

import { copywriting } from 'data/copywriting'

import { media } from 'styles/utils'
import { theme } from 'styles/theme'

const CONTACT_BREAKPOINT = 'desktop'

const MAILING_LINK = process.env.GATSBY_MAILING_LINK


// const Container = styled.section`
//   // display: flex;
//   justify-content: space-between;
//   align-items: center;

//   padding: ${theme.measurements.sectionPaddingVerticalMobile} 0;

//   ${media[CONTACT_BREAKPOINT]`
//     display: block;
//   `}
// `

// const buttonStyle = {
//   letterSpacing: '1px',
//   fontWeight: 500,
//   color: '#FFF'
// }

const headingStyle = {
  // fontWeight: 500,
  color: theme.colors.headings
}

const linkStyle = {
  textDecoration: 'underline'
}


export const ContactSection = () => {

  const handleContact = () => {
    // TODO add analytics
    window.location.href = `mailto:${MAILING_LINK}`
  }

  return (
    <SectionLayout>
      <Statement className='mb-0 c-2-8' style={headingStyle}>
        {copywriting.home.contact.text}
      </Statement>
      <a className='c-2-8' onClick={handleContact}>
        <Statement className='mb-0 light' style={linkStyle}>
          {process.env.GATSBY_MAILING_LINK}
        </Statement>
      </a>
      {/* <PrimaryButton onClick={handleContact}>
        <Statement className='mb-0' style={buttonStyle}>
          {copywriting.home.contact.callToAction}
        </Statement>
      </PrimaryButton> */}
    </SectionLayout>
  )
}
