import styled from '@emotion/styled'

import { media } from 'styles/utils'
import { theme } from 'styles/theme'
import { config } from 'styles/config'

export const SectionLayout = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 1em;
  padding: ${p => p.theme.measurements.sectionPaddingVertical} 0;

  ${media[config.mobileBreakpoint]`
    display: block;
    padding: ${theme.measurements.sectionPaddingVerticalMobile} 0;
  `}
`