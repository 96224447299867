import React, { FC } from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { Caption } from 'styles/typography'

interface IVersionNumberProps {
  dashboard?: boolean
  style?: object
}

export const VersionNumber: FC<IVersionNumberProps> = ({ dashboard, style }) => {
  return (
    <StaticQuery
      query={query}
      render={data =>
        <Caption
          style={style}
          className={`app-version mb-0 ${dashboard ? 'container' : ''}`}>
          v{data.site.siteMetadata.version}
        </Caption>}
    />
  )
}

const query = graphql`
  query VersionNumberQuery {
    site {
      siteMetadata {
        version
      }
    }
  }
`
