import React, { FC } from 'react'
import styled from '@emotion/styled'

import { Nav } from 'components/layout/Nav'
import { Footer } from 'components/layout/Footer'

const Content = styled.div`
  height: auto;
  max-width: 1440px;
  margin: 0 auto;

  // padding-top: 6vw;
  padding-left: 6vw;
  padding-right: 6vw;
`

interface ILayoutProps {
  children: React.ReactNode
}

export const PageLayout: FC<ILayoutProps> = ({ children }) => (
  <>
    <Content>
      <Nav />
      {children}
    </Content>
    <Footer />
  </>
)
