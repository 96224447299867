import React from 'react'
import styled from '@emotion/styled'


import { Caption } from 'styles/typography'
import { config } from 'styles/config'
import { media } from 'styles/utils'

import { SectionLayout } from 'layouts/SectionLayout'
import { ContactSection } from 'components/home/ContactSection'
import { VersionNumber } from 'components/VersionNumber'
import { ShaderScreen } from 'components/ShaderScreen'

import smoke from 'shaders/smoke.glsl'


const PADDING_NORMAL = '6vw' // screen size < 1440
const PADDING_LARGE = '86.4px' // 6vw at a screen size of 1440px

const Container = styled.footer`
  // background ${p => p.theme.colors.footerBackground};
  background transparent;
  position: relative;
`

const Content = styled.div`
  height: 100%;
`

const FootnoteLayout = styled(SectionLayout)`
  width: 100%;
  padding: 0;

  ${media[config.mobileBreakpoint]`
    padding: 0;
  `}
`

const Footnote = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  padding-bottom: ${PADDING_LARGE};
  ${media.xlarge`
    padding-bottom: ${PADDING_NORMAL};
  `}

`

export const Footer = () => {
  return (
    <Container>
        <ShaderScreen shader={smoke} responsive background />
      <Content className='container'>
        <ContactSection />

        <FootnoteLayout>
          <Footnote className='c-2-8'>
            <Caption className='app-version mb-0'>&copy; 2019 Floating Points Pty Ltd.</Caption>
            <VersionNumber />
          </Footnote>
        </FootnoteLayout>

      </Content>
    </Container>
  )
}
